<template>
  <section>
    <b-overlay :show="loading">
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Col: Left (albaran Container) -->
            <b-col
              cols="12"
              lg="9"
              xl="9"
              md="9"
            >
              <b-card no-body>
                <!-- Header -->
                <b-card-body class="pb-0">
                  <h3 class="text-primary mb-2">
                    Codigo #{{ albaranData.codigo }}
                  </h3>
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <b-card-text class="mb-1">
                        <strong> Pedido creado por: </strong>
                        {{
                          albaranData.fk_user_creado || user ? user.name : '-'
                        }}
                      </b-card-text>
                      <b-card-text class="mb-1">
                        <strong> Departamento:</strong>
                        {{ albaranData.pedido_departamento_titulo }}
                      </b-card-text>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <b-row
                        align-h="end"
                        class="pr-1 mb-2"
                      >
                        <b-badge :variant="statusVariant(albaranData.estado)">{{
                          statusLabel(albaranData.estado)
                        }}
                        </b-badge>
                      </b-row>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title pr-1"> Fecha: </span>
                        <validation-provider
                          #default="validationContext"
                          name="Fecha"
                          rules="required"
                        >
                          <b-form-group
                            :state="getValidationState(validationContext)"
                          >
                            <flat-pickr
                              v-model="albaranData.fecha"
                              class="form-control"
                              :config="{
                                format: 'd-m-Y',
                                altFormat: 'd-m-Y',
                                altInput: true,
                                allowInput: true,
                                altInput: true,
                                locale: Spanish,
                              }"
                              :disabled="albaranData.estado === 'entregado'"
                            />
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Entregado a -->
                <b-card-body>
                  <label>Entregado a</label>
                  <v-select
                    v-model="albaranData.fk_empleado_recoge"
                    placeholder="Selecione"
                    :options="empleados"
                    :clearable="true"
                    label="empleadoInfo"
                    :reduce="(val) => val.id"
                    :disabled="albaranData.estado === 'entregado'"
                  />
                </b-card-body>

                <!-- Note -->
                <b-card-body class="albaran-padding pt-0">
                  <span class="font-weight-bold">Nota: </span>
                  <b-form-textarea
                    v-model="albaranData.nota"
                    :disabled="albaranData.estado === 'entregado'"
                  />
                </b-card-body>

                <!-- Spacer -->
                <hr>

                <h3 class="pl-2 mb-1">
                  Detalles de la entrega
                </h3>

                <b-table
                  :items="albaranItems"
                  responsive
                  striped
                  :fields="tableHeaders"
                >
                  <!-- Ref Prod -->
                  <template #cell(refProd)="data">
                    {{ data.item.refProd }}
                  </template>
                  <!-- Descripcion -->
                  <template #cell(descripcion)="data">
                    <router-link
                      target="_blank"
                      :to="{ name: 'material-edit', params: { id: data.item.fk_material } }"
                    >
                      <slot>{{ data.item.descripcion_custom || data.item.descripcion }}
                        <feather-icon
                          icon="ExternalLinkIcon"
                          size="16"
                          class="align-top ml-0"
                        />
                      </slot>
                    </router-link>
                  </template>

                  <!-- Cantidad Solicitada -->
                  <template #cell(unit_solicitadas)="data">
                    {{ data.item.unit_solicitadas }}
                  </template>

                  <!-- Cantidad Entregada -->
                  <template #cell(unit_entregadas)="data">
                    <b-form-group
                      v-if="data.item.delivered_on"
                    >
                      <span>
                        {{ data.item.unit_entregadas }} entregadas el {{ formatDate(data.item.delivered_on) }}
                      </span>
                    </b-form-group>
                    <validation-provider
                      v-else
                      #default="validationContext"
                      name="Cantidad Entregada"
                    >
                      <b-form-group
                        v-if="data.item.delivered_on"
                      >
                        <span>
                          {{ data.item.unit_entregadas }} entregadas el {{ formatDate(data.item.delivered_on) }}
                        </span>
                      </b-form-group>
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >

                        <small>{{ data.item.stock || 0 }} disponibles en
                          stock</small>
                        <b-form-input
                          v-model="data.item.unit_entregadas"
                          type="number"
                          :disabled="
                            data.item.unit_entregadas ===
                              data.item.unit_solicitadas
                          "
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        <small
                          v-if="data.item.delivered_part"
                          style="color: blue"
                        >Ya se han entregado {{data.item.unit_entregadas}} de {{data.item.unit_solicitadas}}
                        </small><br>
                        <small
                          v-if="!data.item.delivered_on"
                          style="color: red"
                        >{{ checkStock(data.item) }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </template>
                </b-table>
              </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col
              cols="12"
              lg="3"
              md="3"
              xl="3"
              class="albaran-actions mt-md-0 mt-2"
            >
              <!-- Action Buttons -->
              <b-card>
                <!-- Button: Save albaran -->
                <b-button
                  v-if="albaranData.estado !== 'entregado'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  <feather-icon
                    icon="SaveIcon"
                    size="16"
                  />
                  Guardar
                </b-button>

                <!-- Button: Send albaran -->
                <b-button
                  v-if="$route.params.id !== 'new'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mb-75"
                  block
                  :disabled="invalid"
                  @click="printAlbaran(albaranData.id)"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    size="16"
                  />
                  Imprimir
                </b-button>

                <b-button
                  v-if="$route.params.id !== 'new'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="warning"
                  class="mb-75"
                  block
                  @click="goToPedido(albaranData.fk_pedido)"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="16"
                  />
                  Ir a pedido
                </b-button>

                <!-- Button: Delete -->
                <b-button
                  v-if="canDeleteAlbaran"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  block
                  @click="deleteModal = true"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                  Eliminar
                </b-button>

                <!-- Spacer -->
                <div v-if="albaranData.estado !== 'entregado'">
                  <hr>
                  <label>Cambiar estado</label>
                  <v-select
                    v-model="albaranData.estado"
                    :options="albaranes.estados"
                    :reduce="(est) => est.value"
                    :clearable="false"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>

    <ConfirmationModal
      :id="`confirmation-${$route.params.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el albaran."
      title="¿Está seguro de que desea eliminar el albaran?"
      @action="deleteAlbaran($route.params.id)"
      @close="deleteModal = false"
    />

    <AlertModal
      :id="`alert-${$route.params.id}`"
      :show="alertModal"
      :message="alertMsg"
      :title="alertTitle"
      variant="danger"
      :is-ok-only="true"
      @close="alertModal = false"
      @action="alertModal = false"
    />

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BBadge,
  BTable,
  BTooltip,
  BFormInvalidFeedback,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { Spanish } from 'flatpickr/dist/l10n/es'
import flatPickr from 'vue-flatpickr-component'
import albaranesStoreModule from '@/views/almacen/albaranes/albaranesStoreModule'
import Albaran from '@/models/Albaran'
import materialesStoreModule from '@/views/almacen/materiales/materialesStoreModule'
import { albaranes } from '@/utils/albaranes'
import router from '@/router'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import AlertModal from '@/components/AlertModal.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import moment from 'moment'
import useEmpleadosList from '@/views/system/empleado/useEmpleadosList'
import empleadoStoreModule from '@/views/system/empleado/empleadoStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    BPopover,
    BTable,
    BTooltip,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    ConfirmationModal,
    AlertModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      deleteModal: false,
      alertModal: false,
      alertMsg: '',
      alertTitle: '',
      selectedItem: {},
      Spanish,
      totalEntregado: 0,
      albaranes,
      required,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    canDeleteAlbaran() {
      return !!(this.albaranData.estado === 'pendiente'
          && this.albaranData.fk_empleado_recoge === null && this.noDeleteAlbaran)
    },
    loading() {
      return this.$store.state['app-albaranes'].loading
    },
  },
  methods: {
    checkStock(item) {
      return item.stock < item.unit_solicitadas
        ? 'No hay suficientes materiales en stock para entregar todo el pedido!'
        : ''
    },
    printAlbaran(id) {
      this.$store
        .dispatch('app-albaranes/printAlbaran', id)
        .then(response => {
          window.open(response.route, '_blank')
        })
        .catch(error => {
          console.error('Error printing el albarán:', error)
        })
    },
    goToPedido(idPedido) {
      this.$router.push({
        name: 'pedido-edit',
        params: { id: idPedido },
      })
    },
    deleteAlbaran(id) {
      this.$store.dispatch('app-albaranes/deleteAlbaran', id)
        .then(() => {
          this.$router.push({ name: 'albaranes-list' })
        })
    },
    statusLabel(status) {
      return albaranes.estados.find(el => el.value === status)?.label
    },
    statusVariant(status) {
      return albaranes.estados.find(el => el.value === status)?.variant
    },
    onSubmit() {
      this.albaranData.fk_user_update = this.user.id
      this.albaranData.detalles = [...this.albaranItems]

      if (
        this.albaranData.fk_empleado_recoge
          && this.albaranData.fk_empleado_recoge.id
      ) {
        this.albaranData.fk_empleado_recoge = this.albaranData.fk_empleado_recoge.id
      }

      let invalidDelivery = false

      this.albaranData.detalles.forEach(a => {
        const entregadas = Number(a.unit_entregadas)
        const solicitadas = Number(a.unit_solicitadas)
        const stockEntregadas = (Number(a.stock) - entregadas) + Number(a.ya_entregadas)

        if (entregadas !== 0) {
          if (Number(a.stock) < 1 && entregadas > Number(a.ya_entregadas)) invalidDelivery = true
          if (entregadas > solicitadas) invalidDelivery = true

          if (invalidDelivery) {
            this.alertModal = true
            this.alertMsg = 'No puede entregar unidades solicitadas de materiales que no tienen stock. Refresca la página o revisa el campo entrega de cada material.'
            this.alertTitle = 'Error'
          }
        }

        if (entregadas === solicitadas && !invalidDelivery) {
          if (a.delivered_on === null) {
            moment.locale('es')
            a.delivered_on = moment(new Date().toDateString())
              .format('YYYY-MM-DD HH:mm:ss')
          }
          this.totalEntregado += 1
        }
      })

      if (this.totalEntregado >= this.albaranData.detalles.length && !invalidDelivery) {
        this.albaranData.estado = 'entregado'
      }

      if (this.albaranData.estado === 'entregado' && this.albaranData.fk_empleado_recoge && !invalidDelivery) {
        this.$store.dispatch('app-empleado/updateEmpleadoUniformStatus', {
          empleadoId: this.albaranData.fk_empleado_recoge,
          uniformStatus: true,
        })
      }
      if (!invalidDelivery) {
        this.$store
          .dispatch('app-albaranes/addAlbaran', this.albaranData)
          .then(() => {
            this.$router.push({ name: 'albaranes-list' })
          })
      }
    },
  },
  setup() {
    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'
    const ALBARANES_APP_STORE_MODULE_NAME = 'app-albaranes'
    const EMPLEADO_APP_STORE_MODULE_NAME = 'app-empleado'

    // Register module

    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }

    if (!store.hasModule(ALBARANES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        ALBARANES_APP_STORE_MODULE_NAME,
        albaranesStoreModule,
      )
    }

    if (!store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.registerModule(EMPLEADO_APP_STORE_MODULE_NAME, empleadoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)

      if (store.hasModule(ALBARANES_APP_STORE_MODULE_NAME)) store.unregisterModule(ALBARANES_APP_STORE_MODULE_NAME)

      if (store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLEADO_APP_STORE_MODULE_NAME)
    })

    const noDeleteAlbaran = ref(false)

    const {
      fetchEmpleadosNoPaginate,
      searchQuery,
      totalEmpleados,
    } = useEmpleadosList()

    const empleados = ref([])

    const refetchEmpleados = async () => {
      try {
        const res = await fetchEmpleadosNoPaginate()

        const { total, data } = res
        totalEmpleados.value = total
        const filterActive = data.filter(el => el.estado === 1)
        empleados.value = filterActive.map(el => ({
          ...el,
          empleadoInfo: `#${el.matricula} - ${el.nombre} ${el.apellidos} - ${el.departamento_name}`,
        }))

        totalEmpleados.value = filterActive.length
      } catch (e) {
        console.error('Error fetching empleados:', e)
      }
    }

    refetchEmpleados()

    const albaranData = ref(new Albaran({}))

    const albaranItems = ref([])

    const updateItemForm = (index, val) => {
      const { unit_entregadas } = val
      albaranItems.value[index].unit_entregadas = unit_entregadas
    }

    store
      .dispatch('app-albaranes/fetchAlbaran', router.currentRoute.params.id)
      .then(async response => {
        albaranData.value = response
        const { detalles } = albaranData.value
        if (albaranData.value.fk_empleado_recoge) {
          albaranData.value.fk_empleado_recoge = empleados.value.find(
            el => el.id === albaranData.value.fk_empleado_recoge,
          )
        }

        for (let i = 0; i < detalles.length; i++) {
          const material = await store.dispatch(
            'app-materiales/fetchMaterial',
            detalles[i].fk_material,
          )

          const object = Object.assign(detalles[i], {
            refProd: material.codigo,
            talla: material.talla,
            stock: material.stock,
            tipo: material.tipo,
            desechable: material.desechable === 1,
          })

          if (detalles[i].unit_entregadas === 0) detalles[i].delivered_part = false

          if (detalles[i].unit_entregadas !== detalles[i].unit_solicitadas) {
            noDeleteAlbaran.value = true
            detalles[i].delivered_part = true
            detalles[i].ya_entregadas = detalles[i].unit_entregadas
          }

          if (detalles[i].delivered_on !== null) {
            noDeleteAlbaran.value = true
          }

          albaranItems.value.push(object)
        }
      })

    const tableHeaders = ref([
      {
        key: 'refProd',
        label: 'REF PROD',
        sortable: false,
      },
      {
        key: 'descripcion',
        label: 'Descripcion',
        sortable: false,
      },
      {
        key: 'unit_solicitadas',
        label: 'Cant. Solicitada',
        sortable: false,
      },
      {
        key: 'unit_entregadas',
        label: 'Cant. Entregada',
        sortable: false,
      },
    ])

    const resetAlbaranData = () => {
      albaranData.value = JSON.parse(JSON.stringify(new Albaran({})))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAlbaranData)

    return {
      albaranData,
      updateItemForm,
      albaranItems,
      tableHeaders,
      refFormObserver,
      getValidationState,
      resetForm,
      searchQuery,
      empleados,
      refetchEmpleados,
      noDeleteAlbaran,
    }
  },
}
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
    .pagination {
        display: flex;
        margin: 0.25rem 0.25rem 0;
    }

    .pagination button {
        flex-grow: 1;
    }

    .pagination button:hover {
        cursor: pointer;
    }
</style>
